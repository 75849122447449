import PropTypes from 'prop-types';
import { get } from 'lodash';
import { EdmundsAPI } from 'client/data/api/api-client';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { withMetrics } from 'client/data/api/api-metrics';
import { getChildrenAllMetadata } from 'client/data/cms/compose';
import { parseContent } from 'client/data/cms/content';
import {
  PHOTO_TYPE,
  VIDEO_TYPE,
  CATEGORIES_PLAIN,
  ALL_CAT_NAME,
} from 'site-modules/shared/constants/photoflipper/photoflipper';
import { formatPhotosURL } from 'client/data/utils/format-urls';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import gql from 'graphql-tag';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { CmsModel } from './cms';

export const PfMediaDataItemEntity = PropTypes.shape({
  type: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thumbnailURL: PropTypes.string,
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  contentType: PropTypes.string,
  pageNum: PropTypes.string,
  modelYearId: PropTypes.number,
});

export const SpinPhotosEntity = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string,
    category: PropTypes.string,
    provider: PropTypes.string,
    color: PropTypes.string,
    shotTypeAbbreviation: PropTypes.oneOf(['EXP', 'INP']),
    photoStack: PropTypes.arrayOf(PropTypes.string),
    submodels: PropTypes.arrayOf(PropTypes.string),
    trims: PropTypes.arrayOf(PropTypes.string),
    styleIds: PropTypes.arrayOf(PropTypes.string),
    exactStyleIds: PropTypes.arrayOf(PropTypes.string),
  })
);

export const VinPhotosEntity = PropTypes.arrayOf(
  PropTypes.shape({
    shotType: PropTypes.string,
    imagePath: PropTypes.string,
    imageRecognitionData: PropTypes.shape({
      shotTypeProbability: PropTypes.number,
    }),
    originalWidth: PropTypes.number,
    originalHeight: PropTypes.number,
  })
);

export const PHOTO_SIZE = '815';
export const PHOTO_SIZE_FALLBACK = {
  '600': '600,500',
  '815': '815,500',
  '1280': '1280,815',
};
// photos/videos amount to load in photoflipper
export const PAGE_SIZE = 25;
export const VIDEO_REQUEST_YEARS_DELTA = 2;
// page size to load on m/m/y/pictures/ page
export const GALLERY_PAGE_SIZE = 48;

const FALLBACK_THRESHOLD = 30;
const COLORS_THRESHOLD = 5;
// threshold for exterior + interior categories
const ALL_CATEGORY_THRESHOLD = 2 * FALLBACK_THRESHOLD;

// photos amount to load in all categories url
export const PAGE_SIZE_ALL_CATEGORIES = 5;
const API_ALLOWED_CATEGORIES = ['exterior', 'interior', 'details'];
const CATEGORIES_BY_TYPE = {
  [PHOTO_TYPE]: ['exterior', 'interior', 'colors'],
  [VIDEO_TYPE]: ['all'],
};
// first page number of photos to get quantity of photos for each category
const DEFAULT_PAGE_NUM = 1;
const DEFAULT = 'default';

export const buildAllPhotosPath = ({ make, model, submodel, year, pageNum, imageSize }) =>
  submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]["${PHOTO_TYPE}"]${
        imageSize && imageSize.toString() !== PHOTO_SIZE ? `.imageSize["${imageSize}"]` : ''
      }.category.all[${pageNum}]`
    : `makes["${make}"].models["${model}"].years["${year}"]["${PHOTO_TYPE}"]${
        imageSize && imageSize.toString() !== PHOTO_SIZE ? `.imageSize["${imageSize}"]` : ''
      }.category.all[${pageNum}]`;

export const buildColorsPhotosPath = ({ make, model, submodel, year, pageNum }) =>
  submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]["${PHOTO_TYPE}"].category.colors[${pageNum}]`
    : `makes["${make}"].models["${model}"].years["${year}"]["${PHOTO_TYPE}"].category.colors[${pageNum}]`;

export const buildPhotosMediaPath = ({ make, model, submodel, year, category, pageNum }) => {
  if (!category || category === ALL_CAT_NAME) return buildAllPhotosPath({ make, model, submodel, year, pageNum });
  if (category === CATEGORIES_PLAIN.COLORS) return buildColorsPhotosPath({ make, model, submodel, year, pageNum });

  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]["${PHOTO_TYPE}"].category["${category}"][${pageNum}]`
    : `makes["${make}"].models["${model}"].years["${year}"]["${PHOTO_TYPE}"].category["${category}"][${pageNum}]`;
};

export const buildVideoMediaPath = ({ make, model, submodel, year, category, pageNum }) =>
  submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]["${VIDEO_TYPE}"].category["${category}"][${pageNum}]`
    : `makes["${make}"].models["${model}"].years["${year}"]["${VIDEO_TYPE}"].category["${category}"][${pageNum}]`;

export const buildMediaPath = ({ make, model, submodel, year, category, pageNum, mediaType }) =>
  mediaType === PHOTO_TYPE
    ? buildPhotosMediaPath({ make, model, submodel, year, category, pageNum })
    : buildVideoMediaPath({ make, model, submodel, year, category, pageNum });

export const buildMediaPhotosCountsPath = ({ make, model, submodel, year }) =>
  submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]["${PHOTO_TYPE}"].counts`
    : `makes["${make}"].models["${model}"].years["${year}"]["${PHOTO_TYPE}"].counts`;

export const buildMediaVideoCountsPath = ({ make, model, submodel, year }) =>
  submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]["${VIDEO_TYPE}"].counts`
    : `makes["${make}"].models["${model}"].years["${year}"]["${VIDEO_TYPE}"].counts`;

export const buildMediaCountsPath = ({ make, model, submodel, year, mediaType }) => {
  if (mediaType)
    return mediaType === PHOTO_TYPE
      ? buildMediaPhotosCountsPath({ make, model, submodel, year })
      : buildMediaVideoCountsPath({ make, model, submodel, year });

  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].counts`
    : `makes["${make}"].models["${model}"].years["${year}"].counts`;
};

export const buildSpinPhotosPath = ({ make, model, submodel = DEFAULT, year }) =>
  `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].spinPhotos`;

export const buildVinPhotosPath = ({ vin }) => `vinPhotos["${vin}"]`;

/**
 * Removes all but the largest width photos.
 * Example (result with two sources):
 * {photos: [{sources: [{size: {width: 500}}, {size: {width:815}}]}]}
 * returns
 * {photos: [{sources: [{size: {width: 815}}]}]}
 */
export function removeLowerResolutionPhotos({ photos, ...result }) {
  const highResolutionPhotos =
    photos &&
    photos.map(({ sources, ...photo }) => {
      let highestWidthPhotoSources = [];
      if (sources.length > 0) {
        highestWidthPhotoSources = [sources.reduce((max, next) => (max.size.width > next.size.width ? max : next))];
      }
      return { ...photo, sources: highestWidthPhotoSources };
    });
  return { ...result, photos: highResolutionPhotos };
}

/**
 * When photo count is below the threshold, this makes fallback request with sizes.
 * @param {*} context      Model segment context used for API requests
 * @param {*} requestData  Data used to build request URL
 * @param {*} threshhold   Number of photos needed to avoid fallback
 */
function handleFallback(context, requestData, threshhold) {
  return result => {
    if (result && result.photosCount >= threshhold) {
      return result;
    }
    const fallbackRequestData = Object.assign({}, requestData, {
      params: Object.assign({}, requestData.params, {
        width: PHOTO_SIZE_FALLBACK[requestData.params.width] || PHOTO_SIZE_FALLBACK[PHOTO_SIZE],
      }),
    });
    const fallbackUrl = formatPhotosURL(fallbackRequestData);
    return withMetrics(EdmundsAPI, context)
      .fetchJson(fallbackUrl)
      .then(removeLowerResolutionPhotos);
  };
}

export function getPhotosCount(data) {
  return data.reduce(
    (res, value) => {
      if (!(value.photos && value.photos.length)) return res;
      // use the first photo in array to get category of photos
      const category = value.photos[0].category.toLowerCase();
      const all = res.all + value.photosCount;
      return { ...res, [category]: value.photosCount, all };
    },
    { all: 0 }
  );
}

/**
 * Returns counts of data for each category
 * @param data
 */
export function getCount(data) {
  return data.reduce(
    (res, value) => {
      if (!(value && value.data && value.data.length)) return res;
      // use the first item in array to get category of data
      const category = value.data[0].category.toLowerCase();
      // do not sum colors as they are already counted in exterior category, also exclude video
      const all = [CATEGORIES_PLAIN.COLORS, CATEGORIES_PLAIN.VIDEO].includes(category)
        ? res.all
        : res.all + value.totalCount;
      return { ...res, [category]: value.totalCount, all };
    },
    { all: 0 }
  );
}

function transformPhotosResponse({ photos, photosCount, pageNum, category }) {
  const photoSrcPath = '[0].link.href';
  const photoSizePath = '[0].size';
  return {
    data:
      photos &&
      photos.map(
        ({ title, category: photoCategory, sources, modelYearId, provider, shotTypeAbbreviation: shottype }) => ({
          type: PHOTO_TYPE,
          title,
          category: category || photoCategory.toLowerCase(),
          provider,
          shottype,
          src: get(sources, photoSrcPath),
          size: get(sources, photoSizePath),
          pageNum,
          modelYearId,
        })
      ),
    totalCount: photosCount,
  };
}

/**
 * Gets photos from api /media/v2/:make/:model/:year/photos/
 * @param match
 * @param context
 * @returns {Promise<{data, totalCount: *}>}
 */
async function getPhotos(match, context) {
  const { make, model, submodel, year, pageNum, category, imageSize } = match;
  const pageSize = await context.resolveValue('pageSize');
  const requestData = {
    make,
    model,
    year,
    params: {
      pageNum,
      pageSize,
      width: imageSize || PHOTO_SIZE,
      provider: 'OEM,EVOX',
      photoprovider: 'EVOX,OEM',
    },
  };
  if (API_ALLOWED_CATEGORIES.includes(category)) {
    requestData.params.category = category;
  }
  if (submodel) {
    requestData.params.submodel = submodel;
  }
  const apiUrl = formatPhotosURL(requestData);
  const threshold = category === ALL_CAT_NAME ? ALL_CATEGORY_THRESHOLD : FALLBACK_THRESHOLD;

  const { photos, photosCount } = await withMetrics(EdmundsAPI, context)
    .fetchJson(apiUrl)
    .then(handleFallback(context, requestData, threshold));
  return transformPhotosResponse({ photos, photosCount, pageNum, category });
}

/**
 * Attempts to get video content from cms atom file /:make/:model/:year/photos.atom
 * @param match
 * @param context
 * @returns {Promise<*>}
 */
async function getVideos(match, context) {
  const { make, model, year, mediaType, pageNum, category } = match;
  const content = await context.resolveValue(`content["/${make}/${model}/${year}/photos"]`, CmsModel);
  const data = parseContent(content).child('video-content');
  const videoData = getChildrenAllMetadata(data);

  return {
    data: videoData.map(({ videoTitle, videoId, thumbnailURL, type }) => ({
      type: mediaType,
      title: videoTitle,
      category,
      src: videoId,
      thumbnailURL,
      pageNum,
      contentType: type,
    })),
    totalCount: videoData.length,
  };
}

const mediaFetchHandler = {
  [PHOTO_TYPE]: getPhotos,
  [VIDEO_TYPE]: getVideos,
};

function getMedia(match, context) {
  return mediaFetchHandler[match.mediaType](match, context);
}

export const PhotoflipperModel = createModelSegment('photoflipper', [
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].photoGallery[{pageNum}]',
    resolve(match, context) {
      const { make, model, year, pageNum } = match;
      const requestData = {
        make,
        model,
        year,
        params: {
          pageNum,
          pageSize: PAGE_SIZE_ALL_CATEGORIES,
          width: PHOTO_SIZE,
          provider: 'OEM,EVOX',
          photoprovider: 'EVOX,OEM',
        },
      };
      const apiUrl = formatPhotosURL(requestData);
      return withMetrics(EdmundsAPI, context)
        .fetchJson(apiUrl)
        .then(handleFallback(context, requestData, FALLBACK_THRESHOLD))
        .catch(() => ({}));
    },
  },
  {
    path:
      'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"]["{mediaType}"].category["{category}"][{pageNum}]',
    resolve(match, context) {
      return getMedia(match, context);
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"]["{mediaType}"].category["{category}"][{pageNum}]',
    resolve(match, context) {
      return getMedia(match, context);
    },
  },
  {
    path:
      'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"]["{mediaType}"].category.colors[{pageNum}]',
    async resolve(match, context) {
      const { make, model, submodel, year, pageNum } = match;
      const pageSize = await context.resolveValue('pageSize');

      const requestData = {
        make,
        model,
        year,
        params: {
          submodel,
          pageNum,
          pageSize,
          width: PHOTO_SIZE,
          provider: 'OEM,EVOX',
          photoprovider: 'OEM,EVOX',
          groupfield: 'color',
          shottype: 'TDS,FQ,TDS2,S,TDS3,RQ',
        },
        groupBy: true,
      };
      const apiUrl = formatPhotosURL(requestData);
      return withMetrics(EdmundsAPI, context)
        .fetchJson(apiUrl)
        .then(handleFallback(context, requestData, COLORS_THRESHOLD))
        .then(data => transformPhotosResponse({ ...data, ...match, category: CATEGORIES_PLAIN.COLORS }))
        .catch(() => ({}));
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"]["{mediaType}"].category.colors[{pageNum}]',
    async resolve(match, context) {
      const { make, model, year, pageNum } = match;
      const pageSize = await context.resolveValue('pageSize');

      const requestData = {
        make,
        model,
        year,
        params: {
          pageNum,
          pageSize,
          width: PHOTO_SIZE,
          provider: 'OEM,EVOX',
          photoprovider: 'OEM,EVOX',
          groupfield: 'color',
          shottype: 'TDS,FQ,TDS2,S,TDS3,RQ',
        },
        groupBy: true,
      };
      const apiUrl = formatPhotosURL(requestData);
      return withMetrics(EdmundsAPI, context)
        .fetchJson(apiUrl)
        .then(handleFallback(context, requestData, COLORS_THRESHOLD))
        .then(data => transformPhotosResponse({ ...data, ...match, category: CATEGORIES_PLAIN.COLORS }))
        .catch(() => ({}));
    },
  },
  {
    path:
      'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"]["{mediaType}"].category.all[{pageNum}]',
    resolve(match, context) {
      return getMedia({ ...match, category: ALL_CAT_NAME }, context);
    },
  },
  {
    path:
      'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"]["{mediaType}"].imageSize["{imageSize}"].category.all[{pageNum}]',
    resolve(match, context) {
      return getMedia({ ...match, category: ALL_CAT_NAME }, context);
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"]["{mediaType}"].category.all[{pageNum}]',
    resolve(match, context) {
      return getMedia({ ...match, category: ALL_CAT_NAME }, context);
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].counts',
    resolve(match, context) {
      const videoPromise = context.resolveValue(
        buildVideoMediaPath({ ...match, category: VIDEO_TYPE, pageNum: DEFAULT_PAGE_NUM })
      );
      const photoPromises = CATEGORIES_BY_TYPE[PHOTO_TYPE].map(category =>
        context.resolveValue(buildPhotosMediaPath({ ...match, category, pageNum: DEFAULT_PAGE_NUM }))
      );

      return Promise.all(photoPromises.concat([videoPromise]))
        .then(getCount)
        .catch(() => ({}));
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"]["{mediaType}"].counts',
    resolve(match, context) {
      const { mediaType } = match;

      return Promise.all(
        CATEGORIES_BY_TYPE[mediaType].map(category =>
          context.resolveValue(buildMediaPath({ ...match, category, mediaType, pageNum: DEFAULT_PAGE_NUM }))
        )
      )
        .then(getCount)
        .catch(() => ({}));
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].counts',
    resolve(match, context) {
      const videoPromise = context.resolveValue(
        buildVideoMediaPath({ ...match, category: VIDEO_TYPE, pageNum: DEFAULT_PAGE_NUM })
      );
      const photoPromises = CATEGORIES_BY_TYPE[PHOTO_TYPE].map(category =>
        context.resolveValue(buildPhotosMediaPath({ ...match, category, pageNum: DEFAULT_PAGE_NUM }))
      );

      return Promise.all(photoPromises.concat([videoPromise]))
        .then(getCount)
        .catch(() => ({}));
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"]["{mediaType}"].counts',
    resolve(match, context) {
      const { mediaType } = match;

      return Promise.all(
        CATEGORIES_BY_TYPE[mediaType].map(category =>
          context.resolveValue(buildMediaPath({ ...match, category, mediaType, pageNum: DEFAULT_PAGE_NUM }))
        )
      )
        .then(getCount)
        .catch(() => ({}));
    },
  },
  {
    path: 'pageSize',
    resolve() {
      return Promise.resolve(PAGE_SIZE);
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/api/media/v2/acura/integra/2023/spins?submodel=Integra%20Hatchback&shottype=EXP
   * API also has trim, shottype(EXP|INP), color, pagenum and pagesize params. Add here if needed
   * @see buildSpinPhotosPath
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].spinPhotos',
    resolve({ make, model, submodel, year }, context) {
      const params = objectToQueryString({
        submodel: submodel === DEFAULT ? null : submodel,
        shottype: 'EXP',
      });

      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/media/v2/${make}/${model}/${year}/spins?${params}`)
        .then(response => get(response, 'spins', []))
        .catch(() => []);
    },
  },
  /**
   * @see buildVinPhotosPath
   */
  {
    path: 'vinPhotos["{vin}"]',
    async resolve({ vin }, context) {
      const data = await withMetrics(EdmundsGraphQLFederation, context).query(
        gql`
          query($vin: String!) {
            vinPhotos(photoFilter: { vin: $vin }) {
              photos {
                shotType
                imagePath
                imageRecognitionData {
                  shotTypeProbability
                }
                originalHeight
                originalWidth
              }
            }
          }
        `,
        {
          vin,
        }
      );

      return get(data, 'vinPhotos.photos', []);
    },
  },
]);
